@import "./variables";

body {
  min-width: $min-page-width;
  background: url("../../assets/images/content-background.png") no-repeat 50% 1000px;
}

.header {
  display: flex;
  display: -ms-flexbox;
  flex-flow: column;
  -ms-flex-flow: column;
  justify-content: center;
  padding: 0 15px;
  height: 448px;
  background: white no-repeat right center;

  color: $mlh-blue;
  font-weight: 700;
  font-size: $font-size-h1;
  .bio {
    width: 315px;
    font-size: $font-size-h3;
    font-weight: 300;
    color: $mlh-black;
    line-height: 1.1;
  }
  .call-center {
    font-size: $font-size-h2;
  }
  .tel {

  }
  img {
    margin-bottom: 16px;
  }
  .quotation-button-container {
    margin-top: 16px;
  }

  .info-row-car-stripe {
    display: none;
    background: no-repeat right center;
    background-size: 423px;
    background-position-y: -50px;
    height: 100px;
  }

  .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
    background-color: $mlh-blue;
  }

}

.navbar-link {
  padding-left: 10px;
}

.navbar-link-disabled {
  padding-left: 10px;
  opacity: 0.7;
}


.navbar-dropdown {
  float: left;
  overflow: hidden;
}

.navbar-dropdown .navbar-dropdown-btn {
  font-size: 14px;
  font-family: "Roboto",sans-serif;
  border: none;
  outline: none;
  color: white;
  padding: 15px;
  background-color: inherit;
  text-transform: uppercase;
}

.navbar-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  li {
    padding: 3px;
  }
}

.navbar-dropdown:hover .navbar-dropdown-content {
  display: block;
}

.navbar-dropdown:hover .navbar-dropdown-btn {
  background-color: $mlh-light-blue;
}

.navbar-dropdown-content a {
  float: none;
  text-decoration: none;
  display: block;
  text-align: left;
  color: $mlh-blue;
}

.navbar-dropdown-content li:hover {
  background-color: $mlh-light-blue;
  a {
    color:white;
  }
}

.navbar-dropdown-content .active {
  background-color: $mlh-blue;
  a {
    color: white;
  }
}

.mlh-button,
.mlh-button:hover,
.mlh-button:active,
.mlh-button:visited,
.mlh-button:focus {
  display: inline-block;
  text-transform: uppercase;
  padding: 6px 13px;
  min-width: 145px;
  font-size: 16px;
  text-align: center;
  font-weight: 900;
  text-decoration: none;
  transition: all 100ms ease;
}

.mlh-button:hover {
  box-shadow: 3px 3px 5px $gray-light;
  transform: translateY(-2px);
}

.mlh-button-light,
.mlh-button-light:hover,
.mlh-button-light:active,
.mlh-button-light:visited,
.mlh-button-light:focus {
  color: $mlh-blue;
  border: 2px solid #07284d;
  background: url("../../assets/images/light-button-background.png") center center white;
}

.mlh-button-dark,
.mlh-button-dark:hover,
.mlh-button-dark:active,
.mlh-button-dark:visited,
.mlh-button-dark:focus {
  color: white;
  background: url("../../assets/images/dark-button-background.png") center center $mlh-blue;
}

.content {
  min-height: 835px;
  padding-bottom: 50px;
  margin-left: 0;
  margin-right: 0;
  h1 {
    text-transform: uppercase;
  }
  ul {
    padding-left: 20px;
  }
}

.content .left-background {
  width: 585px;
  height: 835px;
  margin-left: -100px;
  background: no-repeat center center;
}

footer {
  background: url("../../assets/images/footer-background.jpg") no-repeat center center $mlh-blue;
  background-size: cover;
  display: flex;
  padding: 21px 4px;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  font-family: Catamaran, sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: $mlh-blue;
  .container {
    text-align: center;
  }
  height: $footer-height;
}

.normalCar {
  .content .left-background {
    background-image: url("../../assets/images/background-index.jpg");
  }
  .header, .info-row-car-stripe {
    background-image: url("../../assets/images/header-background-index.jpg");
  }
  h2 {
    font-style: $font-size-h3;
    font-weight: 300;
  }
}

.services {
  .content .left-background {
    background-image: url("../../assets/images/background-szolgaltatasok.jpg");
  }
  .header, .info-row-car-stripe {
    background-image: url("../../assets/images/header-background-szolgaltatasok.jpg");
  }
  h3 {
    font-size: 18px;
    font-weight: 300;
    display: inline;
  }
}

.partners {
  h1 + div {
    margin-bottom: 10px;
  }
  .partner {
    color: $mlh-blue;
    font-size: medium;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 0;
  }
  .content {
    .left-background {
      background-image: url("../../assets/images/background-partnerek.jpg");
    }
    a,
    a:visited,
    a:active,
    a:focus,
    a:hover {
      margin-top: 4px;
      font-weight: normal;
      font-style: italic;
      color: $mlh-blue;
    }
  }

  .header, .info-row-car-stripe {
    background-image: url("../../assets/images/header-background-partnerek.jpg");
  }
  .enumeration {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .enumeration-item {
      width: 50%;
    }
  }
}

.requestForQuotation {
  .content {
    .left-background {
      background-image: url("../../assets/images/background-ajanlatkeres.jpg");
    }
    .mlh-button {
      margin-top: 2em;
    }
  }
  .header, .info-row-car-stripe {
    background-image: url("../../assets/images/header-background-ajanlatkeres.jpg");
  }

  label {
    display: block;
    color: $mlh-blue;
    line-height: 1;
  }
  label :after {
    content: ": ";
  }
  label[class=error] {
    text-align: right;
    color: red;
    font-weight: normal;
  }
  .form-element {
    width: 100%;
    border: solid 2px $mlh-blue;
  }
  input[type=text] {
    margin-bottom: 6px;
    line-height: 24px;
    padding: 0 2px;
  }
  textarea {
    resize: none;
    min-height: 220px;
    margin-bottom: 6px;
    overflow: hidden;
    transition: height 0.2s;
  }
}

.quotationTemplates {
  .content .left-background {
    background-image: url("../../assets/images/background-mintaAjanlataink.jpg");
  }
  .header, .info-row-car-stripe {
    background-image: url("../../assets/images/header-background-mintaAjanlataink.jpg");
  }
}

.division {
  .navbar {
    font-size: 14px;
  }
  font-size: 16px;

  .content .left-background {
    //background-image: url("../../assets/images/background-default-devision.jpg");
  }

  .header, .info-row-car-stripe {
    //background-image: url("../../assets/images/header-background-default-devision.jpg");
  }

  .car-photo {
    width: 100%;
    text-align: center;
    img {
      width: 100%;
      max-width: 540px;
    }
  }
  h2 {
    font-weight: normal;
    font-size: 17px;
    margin: 0;
  }

  .description {
    margin: 10px 4px;
    color: $mlh-dark-brown;
    .row > div {
      padding-left: 4px;
      padding-right: 4px;
    }
    .property-name {
      text-align: right;
      color: $mlh-blue;
    }
  }

  .prices {
    margin: 10px 4px;
    padding: 20px 12px 10px;
    background-color: $mlh-blue;
    color: white;
    h2 {
      color: white;
    }
    li.extra-top-margin {
      margin-top: 12px;
    }
    .main-price {
      text-align: center;
      margin-top: -22px;
      padding-left: 190px;
    }
  }

  .extra-equipment {
    margin: 20px 4px;
    color: $mlh-dark-brown;
    h3 {
      color: $mlh-blue;
    }
    li {
      width: 50%;
      float: left;
      padding-right: 30px;
      font-size: 14px;
    }
  }
}
.quotationTemplate {

  .navbar {
    font-size: 14px;
  }
  font-size: 16px;

  .content .left-background {
    background-image: url("../../assets/images/background-mintaAjanlataink-aloldal.jpg");
  }

  .header, .info-row-car-stripe {
    background-image: url("../../assets/images/header-background-mintaAjanlataink.jpg");
  }

  .car-photo {
    width: 100%;
    text-align: center;
    img {
      width: 100%;
      max-width: 540px;
    }
  }
  h2 {
    font-weight: normal;
    font-size: 17px;
    margin: 0;
  }

  .description {
    margin: 10px 4px;
    color: $mlh-dark-brown;
    .row > div {
      padding-left: 4px;
      padding-right: 4px;
    }
    .property-name {
      text-align: right;
      color: $mlh-blue;
    }
  }

  .prices {
    margin: 10px 4px;
    padding: 20px 12px 10px;
    background-color: $mlh-blue;
    color: white;
    h2 {
      color: white;
    }
    li.extra-top-margin {
      margin-top: 12px;
    }
    .main-price {
      text-align: center;
      margin-top: -22px;
      padding-left: 190px;
    }
  }

  .extra-equipment {
    margin: 20px 4px;
    color: $mlh-dark-brown;
    h3 {
      color: $mlh-blue;
    }
    li {
      width: 50%;
      float: left;
      padding-right: 30px;
      font-size: 14px;
    }
  }
}

.spaced-ul {
  li {
    margin-bottom: 15px;
  }
}

.division-card,
.car-card {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  border: solid 3px $mlh-blue;
  margin: 15px 0;
  padding: 3px;
  background: white;
  .description-wrapper {
    flex-grow: 1;
    -ms-flex: 1 1 150px;
    display: flex;
    display: -ms-flexbox;
    flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    max-width: 153px;
    .description {
      text-align: right;
      flex-grow: 1;
      -ms-flex: 1 1 150px;
      margin-top: 10px;
      margin-right: 3px;
      .brand, .type {
        font-size: $font-size-h2;
        font-weight: $headings-font-weight;
        color: $mlh-blue;
        line-height: 1.0;
      }
      .year {
        font-size: $font-size-h4;
        color: $mlh-blue;
      }
    }
    .mlh-button {
      flex-grow: 0;
      align-self: flex-end;
      margin-bottom: 3px;
      margin-right: 3px;
    }
  }
  .car-image-container {
    flex-grow: 0;
    img {
      width: 100%;
      max-width: 374px;
    }
  }
}

//changes for electric car site
.electric, .solar {
  .header {
    color: $mlh-green;
  }

  // Electric, Solar sites body changes
  background: url("../../assets/images/content-background-electric.png") no-repeat 50% 1000px;

  .navbar-dropdown:hover .navbar-dropdown-btn {
    background-color: $mlh-light-green;
  }

  .navbar-dropdown-content a {
    color: $mlh-green;
  }

  .navbar-dropdown-content li:hover {
    background-color: $mlh-light-green;
  }

  .navbar-dropdown-content .active {
    background-color: $mlh-green;
    a {
      color: white;
    }
  }

  h2 {
    font-style: $font-size-h3;
    font-weight: 300;
  }

  .mlh-button-light,
  .mlh-button-light:hover,
  .mlh-button-light:active,
  .mlh-button-light:visited,
  .mlh-button-light:focus {
    color: $mlh-green;
    border: 2px solid #074d15;
    background: url("../../assets/images/light-button-electric-background.png") center center white;
  }

  .mlh-button-dark,
  .mlh-button-dark:hover,
  .mlh-button-dark:active,
  .mlh-button-dark:visited,
  .mlh-button-dark:focus {
    background: url("../../assets/images/dark-button-electric-background.png") center center $mlh-green;
  }

  //Electric, Solar sites footer changes
  footer {
    background: url("../../assets/images/footer-background-electric-blur.png") no-repeat center center;
    color: #ffffff;
    text-shadow: 0px 0px 20px #000;
  }
}

.electric {
  .content .left-background {
    background-image: url("../../assets/images/background-electric2.jpg");
  }

  .header, .info-row-car-stripe {
    background-image: url("../../assets/images/header-background-electric5.jpg");
  }
}

.solar {
  .content .left-background {
    background-image: url("../../assets/images/background-electric3.jpg");
  }

  .header, .info-row-car-stripe {
    background-image: url("../../assets/images/header-background-solar1.jpg");
  }
}

.machine {
  .content .left-background {
    background-image: url("../../assets/images/background-machine.jpg");
  }

  .header, .info-row-car-stripe {
    background-image: url("../../assets/images/header-machine.jpg");
  }
}


html.main-site {
  height: 100%;
  width: 100%;
  body {
    height: 100%;
  }

  img {
    position: absolute;
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    z-index: -1;
  }

  .site-selector-container {
    width: 100%;
    height: 100%;
  }

  .site-selector-left-container {
    width: 50%;
    height: 50%;
    float: left;
    border-right: #fff 2px solid;
  }

  .site-selector-middle-container {
    width: 50%;
    height: 50%;
    float: left;
  }

  .site-selector-right-container {
    width: 50%;
    height: 50%;
    float: left;
    border-right: #fff 2px solid;
    border-bottom: #fff 2px solid;
    box-shadow: 0px 0px 2px #999;
  }

  .site-selector-container-C04 {
    width: 50%;
    height: 50%;
    float: left;
    border-bottom: #fff 2px solid;
    box-shadow: 0px 0px 2px #999;
  }


  .site-selector-container-content {
    text-align: center;
    font-family: Arial;
    font-size: 28px;
    font-weight: bold;
    margin-top: 25vh;
    color: white;
    text-shadow: 0px 0px 10px #999;
  }

  .button {
    padding: 9px 35px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px 2px;
    border-color: #fff;
    font-size: 20px;
    color: white;
    text-decoration: none;
    text-shadow: 0px 0px 2px #999;
    box-shadow: 0px 0px 2px #999;
  }

  .button-container {
    margin-top: 30px;
  }

}

/*Sticky footer for error page*/
html.error-page {
  height: 100%;
  body {
    background: #f4f4f4;
    height: 100%;
    img {
      width: 100%;
      max-width: 481px;
    }

    .error-page-container {
      min-height: 100%;
      text-align: center;
      margin-bottom: -$footer-height;
      margin-top: -$navbar-height;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }

    .error-page-container:before {
      content: "";
      display: block;
      height: $navbar-height;
    }
    .error-page-container:after {
      content: "";
      display: block;
      height: $footer-height;
    }
  }
}

@media(max-width: 1199px) {
  .header {
    padding: 0 15px;
    height: 392px;
    background-size: 109% !important;
  }

  .content .left-background {
    width: 400px;
    height: 680px;
    margin-left: -50px;
    background-size: 100%;
  }
  .normalCar .content .left-background {
    margin-left: -80px;
  }
  .requestForQuotation .content .left-background {
    margin-left: -70px;
  }
  .quotationTemplate .content .left-background {
    margin-left: -60px;
  }
  .car-card .car-image-container {
    width: 352px;
  }
}

@media(max-width: 991px) {
  .header {
    padding: 10px 15px 10px;
    height: 300px;
    background-size: 109% !important;
    p {
      margin-bottom: 6px;
    }
    .logo img {
      width: 50%;
    }
    .bio {
      font-size: $font-size-h4;
    }
    .call-center {
      font-size: $font-size-h3;
    }
    .tel {
      font-size: $font-size-h2;
    }
    .mlh-button {
      margin-top: 10px;
    }
    .quotation-button-container {
      margin-top: 0;
    }
  }

  .left-background-row {
    display: none;
  }

  .car-card {
    .description-wrapper {
      max-width: none;
    }
    .car-image-container {
      width: 375px;
    }
  }
}

@media(max-width: 767px) {
  .header .info-row-car-stripe {
    display: block;
  }

  .header {
    padding: 10px 4px 10px;
    height: auto;
    background: white !important;
    p {
      margin-bottom: 6px;
    }
    .logo img {
      width: auto;
    }
    .bio {
      width: auto;
      font-size: $font-size-h4;
    }
    .call-center {
      font-size: $font-size-h3;
    }
    .tel {
      font-size: $font-size-h2;
    }
    .mlh-button {
      margin-top: 10px;
    }
  }

  .content > div {
    padding-left: 4px;
    padding-right: 4px;
  }

  .left-background-row {
    display: none;
  }

  .car-card {
    .description-wrapper {
      max-width: none;
    }
    .car-image-container {
      width: 100%;
      max-width: 375px;
    }
  }
}

/* only for car-cards */
@media(max-width: 584px) {
  .car-card {
    flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    .car-image-container {
      align-self: center;
    }
    .description-wrapper {
      align-self: center;
      width: 100%;
      max-width: 375px;
      .description {
        text-align: left;
      }
    }
  }
}

@media(max-width: 520px) {
  .header .logo img {
    width: 100%;
  }
  .partners .enumeration .enumeration-item {
    width: 100%;
  }

  .quotationTemplate .prices .main-price {
    margin-top: 5px !important;
  }
}

@media(max-width: 390px) {
  .quotationTemplate {
    .description {
      .row > div {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
      }
      .row > div.property-name {
        text-align: left;
        margin-top: 4px;
      }
    }
    .extra-equipment li {
      width: 100%;
    }

    .prices .main-price {
      padding-left: 50px !important;
    }
  }
}
