@import "./variables";
@import "./bootstrap/bootstrap";

h3 {
  font-weight: 300;
}

.navbar-nav {
  text-transform: uppercase;
}

.navbar-language-selector {
  margin: 15px;
  .navbar-link {
    padding-left: 10px;
  }
}

.container {
  min-width: 240px;
}

.alert {
  margin-top: 20px;
}

@media(max-width: 991px) and (min-width: 768px) {
  .navbar-language-selector {
    margin-top: 1px;
    margin-bottom: -38px;
    padding: 9px;
    background-color: lighten($mlh-blue, 6);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .navbar-link {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

@media(max-width: 767px) {
  .navbar-language-selector {
    margin: 15px 0;
    .navbar-link {
      padding-left: 0;
      padding-right: 10px;
    }
  }
}