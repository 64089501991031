@import "bootstrap/bootstrap/variables";

// Own variables
$footer-height: 142px;

$min-page-width: 300px;

$mlh-blue: #07284d;
//$mlh-green: #519500;
$mlh-green: #235e00;
$mlh-black: #333333;
$mlh-light-blue: #8395a6;
$mlh-light-green: #529519;
$mlh-dark-brown: #3e3a3a;

// Bootstrap overrides
$font-family-base: 'Roboto', sans-serif;
$headings-font-weight: 700;
$font-size-h1: 21pt;
$font-size-h2: 16pt;
$font-size-h3: 15pt;
$headings-color: $mlh-blue;

$navbar-inverse-link-color: white;
$navbar-inverse-link-active-color: $mlh-light-blue;
$navbar-inverse-bg: $mlh-blue;
$navbar-inverse-link-active-bg: $mlh-blue;
$navbar-inverse-toggle-hover-bg: $mlh-blue;
$navbar-inverse-toggle-icon-bar-bg: white;
$navbar-inverse-toggle-border-color: $mlh-light-blue;
$navbar-margin-bottom: 0px;

$text-color: $mlh-dark-brown;

